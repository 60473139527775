<template>
  <Header></Header>

  <div class="wrap maincontainer">
    <router-view />
  </div>

  <Footer></Footer>

  <Toast position="top-right" />
  <CookieNotice v-if="this.$store.state.global.cookieNotice" />
</template>

<script>
import { useI18n } from "vue-i18n";
import { useHead } from "@unhead/vue";
import { defineAsyncComponent } from "vue";
import Toast from "primevue/toast";
import Header from "@/components/organisms/Header.vue";
import Footer from "@/components/organisms/Footer.vue";
const CookieNotice = defineAsyncComponent(() => import("@/components/molecules/CookieNotice.vue"));

export default {
  components: {
    Toast,
    CookieNotice,
    Header,
    Footer,
  },
  setup() {
    useHead({
      title: "Autoelectrade | Köp och sälj, delar och verktyg - till alla slags fordon",
      link: [],
    });
    const { locale, t } = useI18n({ inheritLocale: true, useScope: "global" });
    return { locale, t };
  },
  computed: {
    globalLocale() {
      return this.$store.state.global.locale;
    },
    loginStatus() {
      return this.$store.state.account.loginStatus;
    },
  },
  watch: {
    globalLocale(newValue) {
      this.locale = newValue;
    },
    loginStatus(newValue) {
      if (newValue === true) {
        this.$store.dispatch("account/loadProfile");
      }
    },
  },
  mounted() {
    this.locale = this.globalLocale;
    this.axios.defaults.headers.common["Accept-Language"] = this.globalLocale;

    this.$store.dispatch("global/refreshCookieNoticeStatus");

    this.axios.all([
      this.$store.dispatch("global/getAppSettings"),
      this.$store.dispatch("global/getPages"),
      this.$store.dispatch("catalog/loadVehicleTypes"),
      this.$store.dispatch("catalog/loadSubCategories", 0),
    ]);
  },
};
</script>

<style>
:root {
  --red: #e52727;
  --green: #397739;
  --green2: #49b975;

  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--surface-100);
  font-family: "Roboto", sans-serif;
  font-size: 1.16rem;
  margin: 0;
  padding: 0;
}

.wrap {
  min-width: 300px;
  max-width: 1400px;
  margin: 0 auto 0 auto;
}

.maincontainer {
  width: 100%;
  min-width: 300px;
  min-height: 600px;
}

@media (min-width: 768px) {
  .maincontainer {
    min-width: 750px;
    min-height: 850px;
  }
}
</style>
