import axios from "axios";
import store from "@/stores/index";
import { jwtDecode } from "jwt-decode";

const initialState = () => ({
  loginStatus: false,
  currentUser: null,

  password: {
    current: null,
    new: null,
  },
  profile: {},
  ads: [],
});

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    /** Returns true if account is in free mode. Either if site is globally free or if account is a free account */
    isFreeAccount() {
      let paymentmode = store.getters["global/getGlobalSetting"]("payment_mode");
      const isGlobalFreeMode = paymentmode == 0 || !paymentmode;
      return isGlobalFreeMode || store.getters["account/isFree"];
    },
    /** Returns true if account profile is configured as free. */
    isFree(state) {
      return state.profile?.is_free === true || state.profile?.is_free == 1;
    },
    unpublishedAds(state) {
      return state.ads.filter((ad) => ad?.is_published === false || ad?.is_published == 0);
    },
    unpaidAds(state) {
      return state.ads.filter((ad) => ad?.is_paid === false || ad?.is_paid == 0);
    },
  },
  mutations: {
    resetState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    resetPasswordState(state) {
      const newState = initialState();
      state.password = newState.password;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setAds(state, ads) {
      state.ads = ads;
    },
    removeAd(state, id) {
      let index = state.ads
        .map((obj) => {
          return obj.id;
        })
        .indexOf(id);

      state.ads.splice(index, 1);
    },
    updateAd(state, ad) {
      state.ads.forEach((_ad) => {
        if (_ad.id === ad.id) {
          _ad.title = ad.title;
          _ad.description = ad.description;
          _ad.price = ad.price;
          _ad.currency = ad.currency;
        }
      });
    },
    payAd(state, ad) {
      ad.is_paid = true;
    },
    publishAd(state, ad) {
      ad.is_published = true;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("resetState");
    },
    resetPasswordState({ commit }) {
      commit("resetPasswordState");
    },

    async login({ dispatch }, { email, pass }) {
      if (this.state.loginStatus) return;

      const formData = new FormData();

      formData.append("email", email);
      formData.append("pass", pass);

      let params = {
        j: "login",
      };

      return axios.post(import.meta.env.VITE_API_URL, formData, { params }).then((res) => {
        dispatch("setLoginState", res);
      });
    },

    async setLoginState({ state }, res) {
      state.loginStatus = false;

      if (res.headers["x-auth"]) {
        let token = res.headers["x-auth"];
        let decodeToken = jwtDecode(token);

        localStorage.setItem("token", token);
        state.loginStatus = true;
        state.currentUser = decodeToken.uid;
        axios.defaults.headers.common["Auth"] = `Bearer ${token}`;
      }
    },
    async logout({ state, commit }) {
      localStorage.removeItem("token");
      state.loginStatus = false;
      delete axios.defaults.headers.common["Auth"];
      commit("resetState");
    },
    async refreshLoginStatus({ state }) {
      let token = localStorage.getItem("token");
      if (token !== null) {
        let decodeToken = jwtDecode(token);

        let params = {
          j: "validateToken",
        };

        try {
          let res = await axios.get(import.meta.env.VITE_API_URL, { params });

          if (res.status === 200) {
            state.loginStatus = true;
            state.currentUser = decodeToken.uid;
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            localStorage.removeItem("token");
          }
          state.loginStatus = false;
          state.currentUser = null;
        }
      } else {
        state.loginStatus = false;
        state.currentUser = null;
      }
    },
    async loadProfile({ state, commit }) {
      if (!state.loginStatus) return;

      let params = {
        j: "getProfile",
      };

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        if (!response.data) return;
        commit("setProfile", response.data);
      });
    },
    async updateProfile({ state }) {
      if (!state.profile) return;

      const formData = new FormData();

      formData.append("firstname", state.profile.firstname);
      formData.append("lastname", state.profile.lastname);
      formData.append("zipcode", state.profile.zipcode);
      formData.append("phone", state.profile.phone);
      formData.append("email", state.profile.email);

      let params = {
        j: "updateProfile",
      };

      return axios.post(import.meta.env.VITE_API_URL, formData, { params });
    },

    async deleteProfile({ dispatch }) {
      let params = { j: "deleteProfile" };
      return axios.post(import.meta.env.VITE_API_URL, {}, { params }).then(() => {
        dispatch("logout");
      });
    },

    async updatePassword({ state, dispatch }) {
      if (!state.password.current || !state.password.new) {
        throw new Error("New or current password is missing");
      }

      const formData = new FormData();

      formData.append("password", state.password.current);
      formData.append("new-password", state.password.new);

      let params = {
        j: "updatePassword",
      };

      return axios.post(import.meta.env.VITE_API_URL, formData, { params }).finally(() => {
        dispatch("resetPasswordState");
      });
    },
    async loadAds({ state, commit }) {
      if (!state.ads) return;

      let params = {
        j: "getUserAds",
      };

      return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
        if (!Array.isArray(response.data)) return;
        commit("setAds", response.data);
      });
    },
    async markAdsPublished({ getters, commit }) {
      getters.unpublishedAds.forEach((ad) => {
        commit("publishAd", ad);
      });
    },
    async markAdsPaid({ getters, commit }) {
      getters.unpaidAds.forEach((ad) => {
        commit("payAd", ad);
        commit("publishAd", ad);
      });
    },
  },
};
