<template>
  <span v-if="isSearchEnabled | $grid.lg" class="search p-inputgroup">
    <Dropdown
      v-model="selectSearchCategory"
      :options="rootCategories"
      option-label="title"
      :placeholder="t('vehicle_type_dropdown')"
      scroll-height="256px"
    />
    <label for="siteSearch" class="sr-only">Search</label>
    <InputText v-model="keywords" type="text" :placeholder="t('search')" @keyup.enter="search" id="siteSearch" />
    <Button :disabled="isSearchDisabled" icon="pi pi-search" :loading="isSearching" @click="search" aria-label="Search" />
  </span>
</template>

<script>
import { useI18n } from "vue-i18n";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dropdown,
  },
  setup() {
    const { t } = useI18n({ inheritLocale: true, useScope: "global" });
    return { t };
  },
  data() {
    return {
      isSearching: false,
      isSearchDisabled: false,
    };
  },
  computed: {
    xlBreakpoint() {
      return this.$grid.lg;
    },
    isSearchEnabled() {
      return this.$store.state.global.toggleSearch;
    },
    rootCategories() {
      // Get menu model for options with parentId.
      let model = this.$store.getters["catalog/getCategoryModel"](0);
      return model;
    },
    selectSearchCategory: {
      get() {
        return this.$store.state.global.selectSearchCategory;
      },
      set(value) {
        this.$store.dispatch("global/selectSearchCategory", value);
      },
    },
    keywords: {
      get() {
        return this.$store.state.global.keywords;
      },
      set(value) {
        this.$store.dispatch("global/keywords", value);
      },
    },
  },
  watch: {
    xlBreakpoint(newValue) {
      if (newValue === true) {
        if (this.isSearchEnabled) {
          this.$store.commit("global/toggleSearch");
        }
      }
    },
  },
  mounted() {
    if (!this.isEmpty(this.$route.query.keywords)) {
      this.keywords = this.$route.query.keywords;
    }
  },
  methods: {
    search() {
      let query = {};

      if (this.selectSearchCategory) query.category = this.selectSearchCategory.id;
      if (this.keywords) query.keywords = this.keywords;

      this.$router.push({ name: "Parts", query });

      if (this.$route.name == "Parts") {
        this.$store.dispatch("parts/loadPartsByFilter", query);
      }
    },
  },
};
</script>

<style scoped>
.search {
  position: absolute;
  top: 100%;
  left: 0;
}

.p-inputtext {
  background-color: var(--surface-100);
  font-size: 1.16rem;
}

.p-dropdown {
  max-width: 10rem;
}

.p-inputtext:focus {
  background-color: #fff;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .search {
    position: relative;
  }
}
</style>
