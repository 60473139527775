import axios from "axios";
import router from "@/router/index";

const initialState = () => ({
  guestId: null,
  guestSignup: true,
  // Contains data retrieved from backend
  part: {},
  isPublishing: false,
  // Edited/entered data from user
  ad: {
    data: {
      id: 0,
      title: "",
      description: "",
      phone: "",
      phoneHidden: false,
      zipcode: "",
      price: 0,
      currency: "SEK",
    },
    featuredImageFile: {},
    additionalImagesFile: [],
    featuredImage: {},
    additionalImages: [],
    is_published: false,
    // List of category selection in order.
    categorySelection: [],
    category: null,
  },
  categories: {
    data: {
      vehicle: 0,
      manufacturer: 0,
      model: 0,
      category: 0,
    },
  },
  partDetails: {
    data: {
      extra1: "",
      extra2: "",
    },
  },
  vehicleDetails: {
    models: [],
    loadingModels: false,
    data: {
      extra1: "",
      extra2: "",
      extra3: "",
    },
  },
  signup: {
    firstName: "",
    lastName: "",
    phone: "",
    zipcode: "",
    email: "",
    password: "",
  },
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    // --------------------------------
    resetCategorySelection(state) {
      state.ad.categorySelection.length = 0;
    },
    selectCategory(state, { category_id, depth }) {
      let cid = parseInt(category_id);
      if (!state.ad.categorySelection.includes(cid)) {
        if (state.ad.categorySelection.length > depth) {
          state.ad.categorySelection.length = depth;
        }
        state.ad.categorySelection.push(cid);
      }
    },
    // --------------------------------
    resetState(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
    setGuestId(state, value) {
      state.guestId = value;
    },
    setGuestSignup(state, value) {
      state.guestSignup = value;
    },
    setPart(state, value) {
      if (value != null) {
        value["phone_hidden"] = parseInt(value["phone_hidden"]);
      }
      state.part = value;
    },
    setCategorySelection(state, value) {
      state.ad.categorySelection = value;
    },
    payAd(state) {
      state.part.is_paid = true;
    },
    publishAd(state) {
      state.part.is_published = true;
      state.ad.is_published = true;
    },
    setAdId(state, value) {
      state.ad.data.id = value;
    },
    setAdSlug(state, value) {
      state.ad.data.slug = value;
    },
    setAdTitle(state, value) {
      state.ad.data.title = value;
    },
    setAdDescription(state, value) {
      state.ad.data.description = value;
    },
    setAdZipcode(state, value) {
      state.ad.data.zipcode = value.toString().trim().replace(/ +/, "");
    },
    setAdPhone(state, value) {
      // If backend doesn't return phone this function will be called with undefined
      // this if statement can be removed when backend return phone with ad.
      if (value == undefined) {
        value = "";
      }
      state.ad.data.phone = value.toString().trim().replace(/ +/, "");
    },
    setAdPhoneHidden(state, value) {
      let hidden = value === true || value === "1" || value === 1;
      state.ad.data.phoneHidden = hidden ? 1 : 0;
    },
    setAdPrice(state, value) {
      state.ad.data.price = value;
    },
    setAdCurrency(state, value) {
      state.ad.data.currency = value;
    },
    setIsPublished(state, value) {
      if (typeof value != "boolean") {
        value = value == "1" || value == 1 || value == "true";
      }
      state.ad.is_published = value;
    },
    setFeaturedImageFile(state, value) {
      state.ad.featuredImageFile = value;
    },
    clearFeaturedImageFile(state) {
      state.ad.featuredImageFile = {};
    },
    setAdditionalImagesFile(state, value) {
      state.ad.additionalImagesFile = value;
    },
    clearAdditionalImagesFile(state) {
      state.ad.additionalImagesFile = [];
    },
    setFeaturedImage(state, value) {
      state.ad.featuredImage = value;
    },
    setAdditionalImages(state, value) {
      state.ad.additionalImages = value;
    },
    removeFeaturedImage(state) {
      state.ad.featuredImage.isRemoved = true;
    },
    removeAdditionalImage(state, id) {
      let index = state.ad.additionalImages
        .map((x) => {
          return x.id;
        })
        .indexOf(id);
      state.ad.additionalImages[index].isRemoved = true;
    },
    setCategoryVehicle(state, value) {
      state.categories.data.vehicle = value;
    },
    setCategoryManufacturer(state, value) {
      state.categories.data.manufacturer = value;
    },
    setCategoryModel(state, value) {
      state.categories.data.model = value;
    },
    setCategoryCategory(state, value) {
      state.categories.data.category = value;
    },
    setPartExtra1(state, value) {
      state.partDetails.data.extra1 = value;
    },
    setPartExtra2(state, value) {
      state.partDetails.data.extra2 = value;
    },
    setVehicleModels(state, value) {
      state.vehicleDetails.models = value;
    },
    setVehicleExtra1(state, value) {
      state.vehicleDetails.data.extra1 = value;
    },
    setVehicleExtra2(state, value) {
      state.vehicleDetails.data.extra2 = value;
    },
    setVehicleExtra3(state, value) {
      state.vehicleDetails.data.extra3 = value;
    },
    setSignupFirstName(state, value) {
      state.signup.firstName = value;
    },
    setSignupLastName(state, value) {
      state.signup.lastName = value;
    },
    setSignupZip(state, value) {
      state.signup.zipcode = value;
    },
    setSignupEmail(state, value) {
      state.signup.email = value;
    },
    setSignupPassword(state, value) {
      state.signup.password = value;
    },
    setSignupPhone(state, value) {
      state.signup.phone = value;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("resetState");
    },

    loadVehicleModels({ state, commit, dispatch }, manufacturer) {
      state.vehicleDetails.loadingModels = true;

      dispatch("catalog/getModels", manufacturer, { root: true }).then((models) => {
        commit("setVehicleModels", models);
        state.vehicleDetails.loadingModels = false;
      });
    },

    loadPart({ commit, dispatch }, id) {
      return dispatch("getAd", id).then((ad) => {
        if (!ad) {
          ad = null;
        }
        commit("setPart", ad);
      });
    },
    getAd(_, id) {
      let params = {
        j: "getAdById",
        id,
      };
      return axios
        .get(import.meta.env.VITE_API_URL, { params })
        .then((response) => {
          if (!response.data) {
            return null;
          }
          return response.data;
        })
        .catch((err) => {
          if (!err.response || err.response.status != 404) {
            // Part does not exists. Return null.
            console.error("Unexpected response from backend:");
            console.error(err);
          }
          // 404 return, part does not exists.
          return null;
        });
    },

    async loadAd({ commit, dispatch }, { ad = null, id = null, guestId = null }) {
      if (guestId) {
        commit("setGuestId", guestId);
      }
      if (ad) {
        // TODO: Ensure category path is always loaded? Load category path dynamic here?
        id = ad.id;
      }
      // TODO: this causes dual load when coming from my ads which does not load category path...
      //       can fix this if we implement a getCategoryTree function which can call if category path in
      //       ad ojbect if it is not set.
      // if (!ad) {
      ad = await dispatch("getAd", id);
      if (ad == undefined || ad == null) {
        return;
      }
      // }
      await this.dispatch("catalog/loadVehicleTypes");
      let vehicleTypes = this.getters["catalog/getVehicleTypes"]();
      let vehicleType = vehicleTypes.find((obj) => {
        return ad && obj.id == ad.vehicle_type;
      });
      await this.dispatch("catalog/loadManufacturers", vehicleType);
      let manufacturers = this.getters["catalog/getManufacturers"](vehicleType.id);

      let manufacturer = manufacturers.find((obj) => {
        return ad && obj.id == ad.manufacturer_id;
      });

      await this.dispatch("catalog/loadModels", { vehicleType, manufacturer });
      let models = this.getters["catalog/getModels"](manufacturer.id);
      let model = models.find((obj) => {
        return ad && obj.id == ad.model_id;
      });

      let selcats = [];
      ad.category_path.forEach(async (c) => {
        // TODO: Change backend to not return dicts, return simple list with ids.
        selcats.push(parseInt(c["id"]));
      });

      // TODO: Should get only the actual category here. But cannot be done a the moment since
      //       MenuModel hierarcy forces us to retrieve categories in order. Hence we get all
      //       categories for category ad parent.
      //let pid = selcats[selcats.length - 2];
      //await this.dispatch("catalog/loadSubCategories", pid).then(() => {
      //  commit("setCategoryCategory", selcats[selcats.length - 1]);
      //});

      commit("setAdId", ad.id);
      commit("setAdTitle", ad.title);
      commit("setAdSlug", ad.slug);
      commit("setAdDescription", ad.description);
      commit("setAdZipcode", ad.zipcode);
      commit("setAdPhone", ad.phone);
      commit("setAdPhoneHidden", ad.phone_hidden);
      commit("setAdPrice", parseFloat(ad.price));
      commit("setAdCurrency", ad.currency);

      commit("setCategoryVehicle", vehicleType);
      commit("setCategoryManufacturer", manufacturer);
      commit("setCategoryModel", model);
      commit("setCategorySelection", selcats);

      commit("setPartExtra1", ad.extrafield_1);
      commit("setPartExtra2", ad.extrafield_2);

      commit("setVehicleExtra1", ad.car_extrafield_1);
      commit("setVehicleExtra2", ad.car_extrafield_2);
      commit("setVehicleExtra3", ad.car_extrafield_3);
      commit("setIsPublished", ad.is_published);

      let additionalImages = [];
      if (!ad.images) {
        let params = {
          j: "getUserAdImages",
          ad: ad.id,
        };

        return axios.get(import.meta.env.VITE_API_URL, { params }).then((response) => {
          if (!response.data || !ad) return;
          ad.images = response.data;
        });
      }

      ad.images.forEach((image) => {
        const imageObj = {
          id: image.id,
          path: image.filepath,
          thumbnail: image.thumbnailpath,
          isRemoved: false,
        };

        if (image.is_featured == 1) {
          commit("setFeaturedImage", imageObj);
        } else {
          additionalImages.push(imageObj);
        }
      });
      commit("setAdditionalImages", additionalImages);
    },

    async postAd({ state, dispatch }, loginStatus = false) {
      let cats = {
        vehicle: state.categories.data.vehicle.id,
        manufacturer: state.categories.data.manufacturer.id,
        model: state.categories.data.model.id,
        category: state.categories.data.category.id,
      };
      let ad = {
        ad: state.ad.data,
        categories: cats,
        partDetails: state.partDetails.data,
        vehicleDetails: state.vehicleDetails.data,
      };

      if (!loginStatus) {
        ad.guestSignup = state.guestSignup;

        if (state.guestSignup) {
          ad.account = {
            phone: state.signup.phone,
            email: state.signup.email,
            password: state.signup.password,
          };
        } else {
          ad.account = state.signup;
        }
      }

      let data = {
        ad: JSON.stringify(ad),
      };

      if (Object.keys(state.ad.featuredImageFile).length) {
        data.featuredImage = state.ad.featuredImageFile;
      }

      if (Object.keys(state.ad.additionalImagesFile).length) {
        data.additionalImages = state.ad.additionalImagesFile;
      }
      let params = {
        j: "postAd",
      };

      const formData = new FormData();

      for (const key in data) {
        const value = data[key];

        if (Array.isArray(value)) {
          const arrayKey = `${key}[]`;
          value.forEach((v) => {
            formData.append(arrayKey, v);
          });
        } else {
          formData.append(key, value);
        }
      }

      try {
        const res = await axios.post(import.meta.env.VITE_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params,
        });

        if (res.status === 200) {
          if (!loginStatus) {
            dispatch("account/setLoginState", res, { root: true });
          }
        }
        return res;
      } catch (error) {
        return error.response;
      }
    },
    async updateAd({ commit, state }) {
      let cats = {
        vehicle: state.categories.data.vehicle.id,
        manufacturer: state.categories.data.manufacturer.id,
        model: state.categories.data.model.id,
        category: state.categories.data.category.id,
        //category: state.ad.categorySelection[state.ad.categorySelection.length - 1],
      };

      let ad = {
        ad: state.ad.data,
        categories: cats,
        partDetails: state.partDetails.data,
        vehicleDetails: state.vehicleDetails.data,
      };

      let imagesRemoved = [];
      state.ad.additionalImages.forEach((image) => {
        if (image.isRemoved) {
          imagesRemoved.push(image);
        }
      });

      ad.additionalImagesRemoved = JSON.stringify(imagesRemoved);

      let data = {
        ad: JSON.stringify(ad),
      };

      if (Object.keys(state.ad.featuredImageFile).length) {
        data.featuredImage = state.ad.featuredImageFile;
      }

      if (Object.keys(state.ad.additionalImagesFile).length) {
        data.additionalImages = state.ad.additionalImagesFile;
      }

      let params = {
        j: "updateAd",
      };

      if (state.guestId) params.guestId = state.guestId;

      const formData = new FormData();

      for (const key in data) {
        const value = data[key];

        if (Array.isArray(value)) {
          const arrayKey = `${key}[]`;
          value.forEach((v) => {
            formData.append(arrayKey, v);
          });
        } else {
          formData.append(key, value);
        }
      }

      try {
        let res = await axios.post(import.meta.env.VITE_API_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params,
        });
        commit("setAdSlug", res.data.slug);
        return res;
      } catch (error) {
        if (error.response == undefined) {
          error.response = { status: 500 };
          console.error(error);
        }
        return error.request;
      }
    },
    async removeAd({ rootState, commit }, { id, guestId = null, password = null }) {
      let params = {
        j: "removeAd",
        id,
      };

      if (!rootState.account.loginStatus) {
        params.guestId = guestId;
        params.password = password;
      }

      return axios.delete(import.meta.env.VITE_API_URL, { params }).then(() => {
        if (rootState.account.loginStatus) {
          commit("account/removeAd", id, { root: true });
        }
      });
    },
    async publishAds({ rootState, state, commit, dispatch }) {
      let params = {
        j: "publishAds",
        guestId: router.currentRoute._value.params?.guestId,
      };

      const formData = new FormData();
      formData.append("id", rootState.ad.part?.id);

      state.isPublishing = true;

      return axios
        .post(import.meta.env.VITE_API_URL, formData, { params })
        .then(() => {
          if (rootState.account.loginStatus) {
            dispatch("account/markAdsPublished", null, { root: true });
          } else {
            commit("publishAd");
          }
        })
        .finally(() => {
          state.isPublishing = false;
        });
    },
    async reportAd(_, data = {}) {
      const formData = new FormData();

      formData.append("id", data.id);
      formData.append("reason", data.reason);

      let params = {
        j: "reportAd",
      };

      return axios.post(import.meta.env.VITE_API_URL, formData, { params });
    },
    async markAdPaid({ commit }) {
      commit("payAd");
      commit("publishAd");
    },
  },
};
