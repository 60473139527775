import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import VueScreen from "vue-screen";
import axios from "axios";
import i18n from "@/plugins/i18n";
import VueAxios from "vue-axios";
import store from "@/stores/index";
import dayjs from "dayjs";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Dialog from "primevue/dialog";
import { gtag, initDataLayer, install } from "ga-gtag";

import "@fontsource/roboto";
import "primevue/resources/themes/saga-orange/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@/assets/global.css";

const app = createApp(App);

app.use(i18n);
app.use(VueScreen, "bootstrap");
app.config.globalProperties.$dayjs = dayjs;

app.mixin({
  methods: {
    numFormat(currency, value) {
      if (!currency && !value) return;
      return new Intl.NumberFormat("en", {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
      }).format(value);
    },
    isEmpty(value) {
      if (value === undefined) return true;
      if (value === null) return true;
      if (value == 0) return true;

      if (typeof value === "object") {
        return !Object.keys(value).length;
      }

      return false;
    },
    replaceNewLine(text) {
      if (text != null) {
        return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
      }
      return text;
    },
  },
});

let token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Auth"] = `Bearer ${token}`;
}

axios.interceptors.request.use((request) => {
  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return request;
});

axios.interceptors.response.use((response) => {
  // console.log('Response:', JSON.stringify(response, null, 2))
  return response;
});
app.use(VueAxios, axios);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(ConfirmationService);

app.component("Button", Button);
app.component("InputText", InputText);
app.component("Card", Card);
app.component("Skeleton", Skeleton);
app.component("Toast", Toast);
app.component("Dialog", Dialog);

app.use(router);
app.use(store);

import { createHead } from "@unhead/vue";
const head = createHead();
app.use(head);

// Unexpected error handling
app.config.errorHandler = (err, instance, info) => {
  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);

  // Add code for UI notifications, reporting or other error handling logic
};
app.mount("#app");

const cookieAnalytics = localStorage.getItem("cookieAllowAnalysis");
const cookieMarketing = localStorage.getItem("cookieAllowMarketing");

if (cookieMarketing === "true") {
  // Facebook Pixel Code
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

    let link = document.createElement("link");
    link.href = "https://connect.facebook.net";
    link.rel = "preconnect";
    document.head.appendChild(link);

  /* global fbq */
  fbq("init", "1740491256289643");
  fbq("track", "PageView");
  // End Facebook Pixel Code
}

// Google GTag initialization
initDataLayer();

gtag("consent", "default", {
  ad_storage: "denied",
  analytics_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  wait_for_update: 500,
});

if (cookieMarketing != null || cookieAnalytics != null) {
  install("G-CPMCPMZFHW");

  gtag("consent", "update", {
    ad_storage: cookieMarketing === "true" ? "granted" : "denied",
    analytics_storage: cookieAnalytics === "true" ? "granted" : "denied",
  });
  let link = document.createElement("link");
  link.href = "https://www.googletagmanager.com";
  link.rel = "preconnect";
  document.head.appendChild(link);
}
